import useRequest, { CommonResponse } from "./../useRequest";
import { computed } from "vue";

export type useInstitutiontParams = {
  name?: string;
};

const useInstitution = (
  getParams: () => useInstitutiontParams = () => ({}),
) => {
  const { data, ...other } = useRequest<CommonResponse<any>>(
    `/app/enum/institution`,
    getParams,
  );
  const enhancedData = computed(() => {
    if (!data.value) return null;
    return data.value.data.data;
  });

  return {
    institution: enhancedData,
    ...other
  };
};

export default useInstitution;
