import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "table table-rounded border gy-7 gs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_el_skeleton = _resolveComponent("el-skeleton")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock("div", _mergeProps({ key: 0 }, _ctx.$attrs), [
          _createVNode("table", _hoisted_1, [
            _createVNode("tbody", null, [
              _createVNode("tr", null, [
                _createVNode(_component_el_skeleton, {
                  style: {"width":"100%"},
                  animated: ""
                }, {
                  template: _withCtx(() => [
                    _createVNode(_component_el_row, { gutter: 20 }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5, 6], (index) => {
                          return _createVNode(_component_el_col, {
                            key: index,
                            span: 4
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_skeleton_item, {
                                variant: "text",
                                style: {"width":"100%"}
                              })
                            ]),
                            _: 2
                          }, 1024)
                        }), 64))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.computedRows, (index) => {
                return (_openBlock(), _createBlock("tr", { key: index }, [
                  _createVNode(_component_el_skeleton, {
                    style: {"width":"100%"},
                    animated: ""
                  }, {
                    template: _withCtx(() => [
                      _createVNode("div", null, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"100%"}
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]))
              }), 128))
            ])
          ])
        ], 16))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? _renderSlot(_ctx.$slots, "default", { key: 1 })
      : _createCommentVNode("", true)
  ], 64))
}