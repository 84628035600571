
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "TableSkeleton",
  props: {
    rows: {
      type: Number,
      default: 7
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const computedRows = computed(() =>
      new Array(props.rows).fill(null).map((_, index) => index)
    );
    return { computedRows };
  }
});
