import useRequest, { CommonResponse } from "./../useRequest";
import { computed } from "vue";

const useChannel = () => {
  const { data, ...other } = useRequest<CommonResponse<any>>(
    `/app/enum/channel`
  );
  const enhancedData = computed(() => {
    if (!data.value) return null;
    return data.value.data.data;
  });

  return {
    channel: enhancedData,
    ...other
  };
};

export default useChannel;
