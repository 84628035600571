
import { defineComponent, computed } from "vue";
import { useMutateQuery } from "../core/helpers/common-helper";

export default defineComponent({
  name: "Pagination",
  props: {
    hideWhenOnlyOnePage: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    totalCurrentPageItem: {
      type: Number,
      required: true
    },
    totalAllItems: {
      type: Number,
      required: true
    },
    currentChange: {
      type: Function,
      required: false,
      default: undefined
    },
    onChange: {
      type: Function,
      required: false,
      default: undefined
    },
    layout: {
      type: String
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    autoMutateQuery: {
      type: Boolean,
      default: false
    },
    pagerCount: {
      type: Number,
      default: 5
    }
  },
  setup(props) {
    // page: 1, fromIndex: 1, untilIndex: 50
    // page: 2, fromIndex: 51, untilIndex: 100
    // page: 3, fromIndex: 101, untilIndex: 150
    const fromIndex = computed(() => 1 + props.perPage * (props.page - 1));
    const untilIndex = computed(
      () => fromIndex.value + props.totalCurrentPageItem - 1
    );

    const { mutateQuery } = useMutateQuery();
    const handleCurrentChange = value => {
      if (props.currentChange) {
        props.currentChange(value);
      }
      if (props.onChange) {
        props.onChange(value);
      }
      if (props.autoMutateQuery) {
        mutateQuery({
          page: value
        });
      }
    };
    const onlyOnePage = computed(() => {
      return props.totalAllItems <= props.totalCurrentPageItem;
    });
    const isNeedToBeHideWhenOnlyOnePage = computed(
      () => props.hideWhenOnlyOnePage && onlyOnePage.value
    );

    return {
      fromIndex,
      untilIndex,
      handleCurrentChange,
      isNeedToBeHideWhenOnlyOnePage
    };
  }
});
